import axios from '@axios'


export class GroupAccommodationService {

  constructor() {}

  async addAccommodationOption(groupId, accommodationOption) {
    return new Promise((resolve, reject) => {
      
      let body = {
        title: accommodationOption.title,
        location: accommodationOption.location,
        country: accommodationOption.country,
        inventory_release_date: accommodationOption.inventory_release_date,
        final_payment_date: accommodationOption.final_payment_date,
        images: accommodationOption.images,
        description: accommodationOption.description,
        type: accommodationOption.type,
        operator_tour: accommodationOption.operator_tour,
        extra_information: accommodationOption.extra_information,
      }

      axios
      .post(`/groups/${groupId}/accommodations`, body)
      .then(response => {
                        resolve(response)
      })
      .catch(error => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
    })
  }

  async editAccommodationOption(groupId, accommodationOption) {
    return new Promise((resolve, reject) => {
      
      let body = {
        title: accommodationOption.title,
        location: accommodationOption.location,
        country: accommodationOption.country,
        inventory_release_date: accommodationOption.inventory_release_date,
        final_payment_date: accommodationOption.final_payment_date,
        images: accommodationOption.images,
        description: accommodationOption.description,
        type: accommodationOption.type,
        operator_tour: accommodationOption.operator_tour,
        extra_information: accommodationOption.extra_information,
      }

      axios
      .put(`/groups/${groupId}/accommodations/${accommodationOption.id}`, body)
      .then(response => {
                        resolve(response)
      })
      .catch(error => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
    })
  }

  async deleteAccommodationOption(groupId, accommodationOption) {
    return new Promise((resolve, reject) => {
      

      axios
      .delete(`/groups/${groupId}/accommodations/${accommodationOption.id}`)
      .then(response => {
                        resolve(response)
      })
      .catch(error => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
    })
  }

  async addRoomToAccommodationOption(groupId, accommodationId, room) {
    return new Promise((resolve, reject) => {
      
      let body = {
        room_category: room.room_category,
        room_option: "INTERIOR",
        exclusions: room.exclusions,
        inclusions: room.inclusions,
        characteristics: room.characteristics,
        description: room.description,
        extra_information: room.extra_information,
        url: room.url,
        inventory: room.inventory,
        check_in: "2022-05-04",
        check_out: "2022-05-06",
      }

      axios
      .post(`/groups/${groupId}/accommodations/${accommodationId}/rooms`, body)
      .then(response => {
                        resolve(response)
      })
      .catch(error => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
    })
  }

  async editRoomOption(groupId, accommodationId, room) {
    return new Promise((resolve, reject) => {
      
      let body = {
        room_category: room.room_category,
        room_option: "INTERIOR",
        exclusions: room.exclusions,
        inclusions: room.inclusions,
        characteristics: room.characteristics,
        description: room.description,
        extra_information: room.extra_information,
        url: room.url,
        inventory: room.inventory,
        check_in: "2022-05-04",
        check_out: "2022-05-06",
      }

      axios
      .put(`/groups/${groupId}/accommodations/${accommodationId}/rooms/${room.id}`, body)
      .then(response => {
                        resolve(response)
      })
      .catch(error => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
    })
  }

  async deleteRoomOption(groupId, accommodationId, room) {
    return new Promise((resolve, reject) => {
      
      axios
      .delete(`/groups/${groupId}/accommodations/${accommodationId}/rooms/${room.id}`)
      .then(response => {
                        resolve(response)
      })
      .catch(error => {
        if (error.response) {
          reject(error.response)
        } else {
          reject(error)
        }
      })
    })
  }

}
